<template name="skeleton">
  <div class="sk-container">
    <div class="container bgf data-v-05f65ad5">
      <div class="relative data-v-05f65ad5">
        <div
          class="customer-info-header flex flex-align-center plr15 data-v-05f65ad5"
        >
          <div
            class="customer-image flex-s0 bdr5 mr12 data-v-05f65ad5 sk-image"
          />
          <div class="customer-detail flex-1 data-v-05f65ad5">
            <div
              class="fs16 fw500 cl29 line-1 data-v-05f65ad5 sk-transparent sk-text-14-2857-222 sk-text"
            >
              潘哥
            </div>
            <div
              class="customer-info-detail flex flex-align-center fs12 data-v-05f65ad5 mt5"
            >
              <span
                class="fw400 cl69 line-1 data-v-05f65ad5 sk-transparent sk-text-14-2857-663 sk-text"
              >
                [任务]❗A_潘
              </span>
              <span
                class="customer-corp flex-s0 cl39 data-v-05f65ad5 sk-transparent sk-text-14-2857-711 sk-text"
              >
                @微信
              </span>
              <div
                class="customer-sex-icon flex-s0 mr4 data-v-05f65ad5 sk-image"
              />
            </div>
          </div>
          <div class="handle-btn-box flex-s0 data-v-05f65ad5">
            <button
              class="handle-btn fs12 data-v-05f65ad5 sk-transparent sk-button sk-pseudo sk-pseudo-circle"
            >
              发微信
            </button>
          </div>
        </div>
        <div
          class="tabs-nav-wrap flex flex-justify-evenly flex-align-center bgf data-v-05f65ad5 sk-pseudo sk-pseudo-circle"
        >
          <div
            class="tab-nav cl9c relative line-1 tc data-v-05f65ad5 tab-nav-active sk-transparent sk-text-34-0909-811 sk-text sk-pseudo sk-pseudo-circle"
            style="background-position-x: 50%"
          >
            客户商机
          </div>
          <div
            class="tab-nav cl9c relative line-1 tc data-v-05f65ad5 sk-transparent sk-text-34-0909-571 sk-text"
            style="background-position-x: 50%"
          >
            资料标签
          </div>
          <div
            class="tab-nav cl9c relative line-1 tc data-v-05f65ad5 sk-transparent sk-text-34-0909-607 sk-text"
            style="background-position-x: 50%"
          >
            客户动态
          </div>
        </div>
        <div class="data-v-05f65ad5" style="padding-bottom: 32px">
          <div class="r data-v-05f65ad5" data-c-h="false">
            <div
              class="container business--container bgf5 business--bgf5 pbs business--pbs data-v-34a815dc business--data-v-34a815dc"
              style="min-height: 279.5px"
            >
              <div
                class="ptb8 business--ptb16 plr15 business--plr30 bgf business--bgf mb8 business--mb16 data-v-34a815dc business--data-v-34a815dc"
              >
                <div
                  class="log-content business--log-content bg49 business--bg49 bdr4 business--bdr8 fs12 business--fs24 clth business--clth lh16 business--lh32 data-v-34a815dc business--data-v-34a815dc"
                >
                  <div
                    class="data-v-34a815dc business--data-v-34a815dc sk-transparent"
                  >
                    <div
                      class="log-icon business--log-icon data-v-34a815dc business--data-v-34a815dc sk-image"
                    />
                    记录
                  </div>
                  <div
                    class="mt8 business--mt16 wb business--wb fw500 business--fw500 data-v-34a815dc business--data-v-34a815dc sk-transparent sk-text-12-5000-406 sk-text"
                  >
                    下周旅行，预约周六护理，销售防晒销售防晒防
                  </div>
                </div>
              </div>
              <div
                class="module-box business--module-box bgf business--bgf plr15 business--plr30 mb8 business--mb16 data-v-34a815dc business--data-v-34a815dc"
              >
                <div
                  class="module-header business--module-header relative business--relative pl10 business--pl20 flex business--flex flex-justify-between business--flex-justify-between flex-align-center business--flex-align-center bdb business--bdb data-v-34a815dc business--data-v-34a815dc sk-pseudo sk-pseudo-circle"
                >
                  <div
                    class="flex business--flex flex-align-center business--flex-align-center data-v-34a815dc business--data-v-34a815dc"
                  >
                    <span
                      class="fs16 business--fs32 fw500 business--fw500 mr8 business--mr16 data-v-34a815dc business--data-v-34a815dc sk-transparent sk-text-14-2857-924 sk-text"
                    >
                      寄存信息
                    </span>
                    <div
                      class="icon-box business--icon-box data-v-34a815dc business--data-v-34a815dc"
                    >
                      <div
                        class="icon business--icon data-v-34a815dc business--data-v-34a815dc sk-image"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="module-content business--module-content data-v-34a815dc business--data-v-34a815dc"
                >
                  <div
                    class="ptb12 business--ptb25 tc business--tc cl9c business--cl9c data-v-34a815dc business--data-v-34a815dc sk-transparent sk-text-14-2857-144 sk-text"
                    style="background-position-x: 50%"
                  >
                    暂无寄存信息
                  </div>
                </div>
              </div>
              <div
                class="module-box business--module-box bgf business--bgf plr15 business--plr30 mb8 business--mb16 data-v-34a815dc business--data-v-34a815dc"
              >
                <div
                  class="module-header business--module-header relative business--relative pl10 business--pl20 flex business--flex flex-justify-between business--flex-justify-between flex-align-center business--flex-align-center bdb business--bdb data-v-34a815dc business--data-v-34a815dc sk-pseudo sk-pseudo-circle"
                >
                  <div
                    class="flex business--flex flex-align-center business--flex-align-center data-v-34a815dc business--data-v-34a815dc"
                  >
                    <span
                      class="fs16 business--fs32 fw500 business--fw500 mr8 business--mr16 data-v-34a815dc business--data-v-34a815dc sk-transparent sk-text-14-2857-519 sk-text"
                    >
                      会员信息
                    </span>
                    <div
                      class="icon-box business--icon-box data-v-34a815dc business--data-v-34a815dc"
                    >
                      <div
                        class="icon business--icon data-v-34a815dc business--data-v-34a815dc sk-image"
                      />
                    </div>
                  </div>
                </div>
                <div class="data-v-34a815dc business--data-v-34a815dc">
                  <div
                    class="module-content business--module-content flex business--flex data-v-34a815dc business--data-v-34a815dc"
                  >
                    <div
                      class="item-box business--item-box flex-1 business--flex-1 flex business--flex flex-direction-column business--flex-direction-column flex-align-center business--flex-align-center ptb12 business--ptb25 relative business--relative data-v-34a815dc business--data-v-34a815dc sk-pseudo sk-pseudo-circle"
                    >
                      <div
                        class="fs12 business--fs24 cl9c business--cl9c mb10 business--mb20 data-v-34a815dc business--data-v-34a815dc sk-transparent sk-text-14-2857-516 sk-text"
                      >
                        等级
                      </div>
                      <div
                        class="fs16 business--fs32 fw500 business--fw500 lh24 business--lh48 data-v-34a815dc business--data-v-34a815dc sk-transparent sk-text-16-6667-518 sk-text"
                      >
                        钻石会员
                      </div>
                    </div>
                    <div
                      class="item-box business--item-box flex-1 business--flex-1 flex business--flex flex-direction-column business--flex-direction-column flex-align-center business--flex-align-center ptb12 business--ptb25 relative business--relative data-v-34a815dc business--data-v-34a815dc sk-pseudo sk-pseudo-circle"
                    >
                      <div
                        class="fs12 business--fs24 cl9c business--cl9c mb10 business--mb20 data-v-34a815dc business--data-v-34a815dc sk-transparent sk-text-14-2857-914 sk-text"
                      >
                        升级还需
                      </div>
                      <div
                        class="fs20 business--fs40 fw500 business--fw500 lh24 business--lh48 data-v-34a815dc business--data-v-34a815dc sk-transparent sk-opacity"
                      >
                        0
                      </div>
                    </div>
                    <div
                      class="item-box business--item-box flex-1 business--flex-1 flex business--flex flex-direction-column business--flex-direction-column flex-align-center business--flex-align-center ptb12 business--ptb25 relative business--relative data-v-34a815dc business--data-v-34a815dc sk-pseudo sk-pseudo-circle"
                    >
                      <div
                        class="fs12 business--fs24 cl9c business--cl9c mb10 business--mb20 data-v-34a815dc business--data-v-34a815dc sk-transparent sk-text-14-2857-257 sk-text"
                      >
                        当前积分
                      </div>
                      <div
                        class="fs20 business--fs40 fw500 business--fw500 clth business--clth lh24 business--lh48 data-v-34a815dc business--data-v-34a815dc sk-transparent sk-opacity"
                      >
                        0
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="module-box business--module-box bgf business--bgf plr15 business--plr30 mb8 business--mb16 data-v-34a815dc business--data-v-34a815dc"
              >
                <div
                  class="module-header business--module-header relative business--relative pl10 business--pl20 flex business--flex flex-justify-between business--flex-justify-between flex-align-center business--flex-align-center bdb business--bdb data-v-34a815dc business--data-v-34a815dc sk-pseudo sk-pseudo-circle"
                >
                  <div
                    class="flex business--flex flex-align-center business--flex-align-center data-v-34a815dc business--data-v-34a815dc"
                  >
                    <span
                      class="fs16 business--fs32 fw500 business--fw500 mr8 business--mr16 data-v-34a815dc business--data-v-34a815dc sk-transparent sk-text-14-2857-386 sk-text"
                    >
                      优惠券
                    </span>
                    <div
                      class="icon-box business--icon-box data-v-34a815dc business--data-v-34a815dc"
                    >
                      <div
                        class="icon business--icon data-v-34a815dc business--data-v-34a815dc sk-image"
                      />
                    </div>
                  </div>
                  <div
                    class="view-more business--view-more flex-s0 business--flex-s0 flex business--flex flex-align-center business--flex-align-center cl9c business--cl9c fs12 business--fs24 data-v-34a815dc business--data-v-34a815dc sk-transparent"
                  >
                    查看更多
                    <div
                      class="icon business--icon data-v-34a815dc business--data-v-34a815dc sk-image"
                    />
                  </div>
                </div>
                <div
                  class="module-content business--module-content data-v-34a815dc business--data-v-34a815dc"
                >
                  <div
                    class="coupon-list business--coupon-list ptb12 business--ptb25 data-v-34a815dc business--data-v-34a815dc"
                  >
                    <div
                      class="coupon-item business--coupon-item flex business--flex flex-align-center business--flex-align-center bdr4 business--bdr8 data-v-34a815dc business--data-v-34a815dc"
                    >
                      <div
                        class="coupon-sum business--coupon-sum flex-s0 business--flex-s0 flex business--flex flex-justify-end business--flex-justify-end flex-align-center business--flex-align-center pr8 business--pr16 data-v-34a815dc business--data-v-34a815dc"
                      >
                        <div
                          class="coupon-icon business--coupon-icon data-v-34a815dc business--data-v-34a815dc sk-image"
                        />
                      </div>
                      <div
                        class="coupon-detail business--coupon-detail flex-1 business--flex-1 ptb8 business--ptb16 data-v-34a815dc business--data-v-34a815dc"
                      >
                        <div
                          class="mb8 business--mb16 data-v-34a815dc business--data-v-34a815dc"
                        >
                          <div
                            class="coupon-title business--coupon-title data-v-34a815dc business--data-v-34a815dc sk-transparent sk-text-14-2857-212 sk-text"
                          >
                            FC测试优惠券0614
                          </div>
                          <div
                            class="fs12 business--fs24 cl9c business--cl9c data-v-34a815dc business--data-v-34a815dc sk-transparent sk-text-14-2857-150 sk-text"
                          >
                            &lt;p&gt;测试三个...据一致&lt;/p&gt;
                          </div>
                        </div>
                        <div
                          class="flex business--flex flex-align-center business--flex-align-center fs12 business--fs24 data-v-34a815dc business--data-v-34a815dc"
                        >
                          <div
                            class="cl9c business--cl9c data-v-34a815dc business--data-v-34a815dc sk-transparent sk-text-14-2857-975 sk-text"
                          >
                            2022-06-17 至 2022-07-16
                          </div>
                          <div
                            class="bgth business--bgth clff business--clff ml6 business--ml12 lh20 business--lh40 plr6 business--plr12 bdr2 business--bdr4 data-v-34a815dc business--data-v-34a815dc sk-transparent sk-text-20-0000-160 sk-text"
                          >
                            9 天后过期
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="coupon-item business--coupon-item flex business--flex flex-align-center business--flex-align-center bdr4 business--bdr8 data-v-34a815dc business--data-v-34a815dc mt8 business--mt16"
                    >
                      <div
                        class="coupon-sum business--coupon-sum flex-s0 business--flex-s0 flex business--flex flex-justify-end business--flex-justify-end flex-align-center business--flex-align-center pr8 business--pr16 data-v-34a815dc business--data-v-34a815dc"
                      >
                        <div
                          class="coupon-icon business--coupon-icon data-v-34a815dc business--data-v-34a815dc sk-image"
                        />
                      </div>
                      <div
                        class="coupon-detail business--coupon-detail flex-1 business--flex-1 ptb8 business--ptb16 data-v-34a815dc business--data-v-34a815dc"
                      >
                        <div
                          class="mb8 business--mb16 data-v-34a815dc business--data-v-34a815dc"
                        >
                          <div
                            class="coupon-title business--coupon-title data-v-34a815dc business--data-v-34a815dc sk-transparent sk-text-14-2857-815 sk-text"
                          >
                            FC测试优惠券0614
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="handle-container flex flex-justify-evenly flex-align-center bgf pt8 fs12 cl9c bdt data-v-05f65ad5"
        >
          <div
            class="flex flex-direction-column flex-align-center data-v-05f65ad5"
            style="color: "
          >
            <div class="handle-icon mb6 data-v-05f65ad5 sk-image" />
            <span
              class="data-v-05f65ad5 sk-transparent sk-text-14-2857-119 sk-text"
            >
              改资料
            </span>
          </div>
          <div
            class="flex flex-direction-column flex-align-center data-v-05f65ad5"
            style="color: "
          >
            <div class="handle-icon mb6 data-v-05f65ad5 sk-image" />
            <span
              class="data-v-05f65ad5 sk-transparent sk-text-14-2857-704 sk-text"
            >
              打标签
            </span>
          </div>
          <div
            class="flex flex-direction-column flex-align-center data-v-05f65ad5"
            style="color: "
          >
            <div class="handle-icon mb6 data-v-05f65ad5 sk-image" />
            <span
              class="data-v-05f65ad5 sk-transparent sk-text-14-2857-60 sk-text"
            >
              记要点
            </span>
          </div>
          <div
            class="flex flex-direction-column flex-align-center data-v-05f65ad5"
            style="color: "
          >
            <div class="handle-icon mb6 data-v-05f65ad5 sk-image" />
            <span
              class="data-v-05f65ad5 sk-transparent sk-text-14-2857-447 sk-text"
            >
              打电话
            </span>
          </div>
          <div
            class="flex flex-direction-column flex-align-center data-v-05f65ad5"
            style="color: #50b674"
          >
            <div class="handle-icon mb6 data-v-05f65ad5 sk-image" />
            <span
              class="data-v-05f65ad5 sk-transparent sk-text-14-2857-592 sk-text"
            >
              发微信
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>

<style lang="less" scoped>
.clth {
  color: #025949;
}
.cl9c {
  color: #86909c;
}
.cl69 {
  color: #4e5969;
}
.cl29 {
  color: #1d2129;
}
.lh16 {
  line-height: 16px;
}
.bgf5 {
  background-color: #f5f5f5;
}
.ptb8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.plr15 {
  padding-left: 15px;
  padding-right: 15px;
}
.plr12 {
  padding-left: 12px;
  padding-right: 12px;
}
.ptb12 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.plr6 {
  padding-left: 6px;
  padding-right: 6px;
}
.bdr4 {
  border-radius: 4px;
}
.bdr5 {
  border-radius: 5px;
}
.bdr2 {
  border-radius: 2px;
}
.mt8 {
  margin-top: 8px;
}
.mr8 {
  margin-right: 8px;
}
.mb8 {
  margin-bottom: 8px;
}
.wb {
  word-break: break-all;
}
.icon-box {
  padding: 8px;
  margin: -8px;
  font-size: 0;
}
.icon {
  width: 16px;
  height: 16px;
}
.bg49 {
  background: rgba(2, 89, 73, 0.08);
}
.ml6 {
  margin-left: 6px;
}
.plr6 {
  padding-left: 6px;
  padding-right: 6px;
}
.pb8 {
  padding-bottom: 8px;
}
.lh20 {
  line-height: 20px;
}
.lh24 {
  line-height: 24px;
}
.title-icon {
  width: 28px;
  height: 28px;
}
.bdb-green {
  border-top: 1px solid rgba(2, 89, 73, 0.1);
}
.clf6 {
  color: #ff6f00 !important;
}
.cl29 {
  color: #1d2129;
}
.cl9c {
  color: #86909c;
}
.mb6 {
  margin-bottom: 6px;
}
// 主题按钮
.handle-btn {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: @theme-color;
  font-weight: 500;
  padding: 0;
  margin: 0;
  border-radius: 5px;
  font-size: 14px;
  line-height: 14px;
  border: none;
}
// 主题按钮-禁用
.handle-btn-d {
  opacity: 0.6;
}
.flex-justify-evenly {
  width: 100%;
  justify-content: space-evenly;
}
.container-view {
  height: 100vh;
  overflow-y: scroll;
}
.customer-info-header {
  padding-top: 14px;
  margin-bottom: 2px;

  .customer-image {
    width: 58px;
    height: 58px;
    border-radius: 5px;
  }
  .customer-detail {
    .customer-info-detail {
      height: 16px;

      .customer-corp {
        color: #07c160;
        margin-left: 4px;
      }
      .customer-sex-icon {
        width: 12px;
        height: 12px;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
    .grade-text {
      height: 20px;
      line-height: 20px;
      padding: 0 8px;
      margin-left: 6px;
    }
  }
  .user-record-write {
    margin-bottom: 2px;

    :deep(.van-field) {
      height: 114px;
      border-radius: 5px;
      box-sizing: border-box;
      border: 1px solid #dfdfdf;
      padding: 12px 8px 8px 12px;

      .van-field__body {
        height: 60px;
      }
      .van-field__control {
        height: 60px !important;
      }
    }
    .cover-layer {
      width: 100%;
      height: 100%;
      background-color: #dfdfdf;
      opacity: 0.3;
      border-radius: 5px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
    }
    .user-record-handle {
      position: absolute;
      right: 8px;
      bottom: 8px;
      z-index: 200;

      .submit-btn {
        width: 74px;
        height: 32px;
        line-height: 32px;
        border-radius: 5px;
      }
      .write-icon {
        width: 34px;
        height: 34px;
      }
    }
  }
  .handle-btn {
    width: 60px;
    height: 32px;
    color: @theme-color;
    background: rgba(19, 174, 103, 0.1);
    border-radius: 4px;
  }
  .orange-theme {
    color: #ff6f00;
    background: rgba(255, 111, 0, 0.1);
  }
  .log-content {
    padding: 8px 12px;

    .log-icon {
      width: 16px;
      height: 16px;
      vertical-align: bottom;
    }
  }
}
.tabs-nav-wrap {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;

  &::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 1px;
    background: #f2f3f5;
  }
  .tab-nav {
    height: 44px;
    line-height: 44px;
    padding: 0 12px;
  }
  .tab-nav-active {
    font-weight: 500;
    color: @theme-color;

    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 150;
      width: 2em;
      height: 2px;
      background: @theme-color;
    }
  }
}
.hint-modal-view {
  height: 122px;
}
.handle-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 8px;
  padding-bottom: calc(8px + env(safe-area-inset-bottom) / 2) !important;

  .handle-icon {
    width: 24px;
    height: 24px;
  }
}
.handle-disabled {
  color: #c9cdd4;
}
.appellation-modal {
  .appellation-input {
    width: 276px;
    height: 44px;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 60px;
    border: 1px solid #e5e6eb;
  }
  .appellation-input-count {
    position: absolute;
    top: 14px;
    right: 12px;
  }
}
.sk-transparent {
  color: transparent !important;
}
.sk-text-14-2857-222 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 22.4px;
  position: relative !important;
}
.sk-text {
  background-origin: content-box !important;
  background-clip: content-box !important;
  background-color: transparent !important;
  color: transparent !important;
  background-repeat: repeat-y !important;
}
.sk-text-14-2857-663 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 16.8px;
  position: relative !important;
}
.sk-text-14-2857-711 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 16.8px;
  position: relative !important;
}
.sk-text-34-0909-811 {
  background-image: linear-gradient(
    transparent 34.0909%,
    #eeeeee 0%,
    #eeeeee 65.9091%,
    transparent 0%
  ) !important;
  background-size: 100% 44px;
  position: relative !important;
}
.sk-text-34-0909-571 {
  background-image: linear-gradient(
    transparent 34.0909%,
    #eeeeee 0%,
    #eeeeee 65.9091%,
    transparent 0%
  ) !important;
  background-size: 100% 44px;
  position: relative !important;
}
.sk-text-34-0909-607 {
  background-image: linear-gradient(
    transparent 34.0909%,
    #eeeeee 0%,
    #eeeeee 65.9091%,
    transparent 0%
  ) !important;
  background-size: 100% 44px;
  position: relative !important;
}
.sk-text-12-5000-406 {
  background-image: linear-gradient(
    transparent 12.5%,
    #eeeeee 0%,
    #eeeeee 87.5%,
    transparent 0%
  ) !important;
  background-size: 100% 16px;
  position: relative !important;
}
.sk-text-14-2857-924 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 22.4px;
  position: relative !important;
}
.sk-text-14-2857-144 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 19.6px;
  position: relative !important;
}
.sk-text-14-2857-519 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 22.4px;
  position: relative !important;
}
.sk-text-14-2857-516 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 19.6px;
  position: relative !important;
}
.sk-text-16-6667-518 {
  background-image: linear-gradient(
    transparent 16.6667%,
    #eeeeee 0%,
    #eeeeee 83.3333%,
    transparent 0%
  ) !important;
  background-size: 100% 24px;
  position: relative !important;
}
.sk-text-14-2857-914 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 19.6px;
  position: relative !important;
}
.sk-opacity {
  opacity: 0 !important;
}
.sk-text-14-2857-257 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 19.8px;
  position: relative !important;
}
.sk-text-14-2857-386 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 22.4px;
  position: relative !important;
}
.sk-text-14-2857-212 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 19.6px;
  position: relative !important;
}
.sk-text-14-2857-150 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 19.8px;
  position: relative !important;
}
.sk-text-14-2857-975 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 19.3px;
  position: relative !important;
}
.sk-text-20-0000-160 {
  background-image: linear-gradient(
    transparent 20%,
    #eeeeee 0%,
    #eeeeee 80%,
    transparent 0%
  ) !important;
  background-size: 100% 20px;
  position: relative !important;
}
.sk-text-14-2857-815 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 19.6px;
  position: relative !important;
}
.sk-text-14-2857-119 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 19.8px;
  position: relative !important;
}
.sk-text-14-2857-704 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 19.8px;
  position: relative !important;
}
.sk-text-14-2857-60 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 19.8px;
  position: relative !important;
}
.sk-text-14-2857-447 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 19.8px;
  position: relative !important;
}
.sk-text-14-2857-592 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 19.8px;
  position: relative !important;
}
.sk-button {
  color: #efefef !important;
  background: #efefef !important;
  border: none !important;
  box-shadow: none !important;
}
.sk-image {
  background: #efefef !important;
}
.sk-pseudo::before,
.sk-pseudo::after {
  background: #efefef !important;
  background-image: none !important;
  color: transparent !important;
  border-color: transparent !important;
}
.sk-pseudo-rect::before,
.sk-pseudo-rect::after {
  border-radius: 0 !important;
}
.sk-pseudo-circle::before,
.sk-pseudo-circle::after {
  border-radius: 50% !important;
}
.sk-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: transparent;
}
</style>
