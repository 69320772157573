<template>
  <div class="container bgf5 pbs relative">
    <div class="module-container mb8 bgf plr15">
      <div
        class="module-title relative flex flex-align-center bdb fs16 fw500 mr8 pl12"
      >
        基本信息
      </div>
      <div class="module-content pb12 mb8">
        <div class="mt12 flex flex-align-center">
          <span class="cl69">称呼：</span>
          <div
            class="line-1"
            :class="[customerInfo.appellation ? 'cl29' : 'cl9c']"
          >
            {{ customerInfo.appellation || '编辑称呼' }}
          </div>
          <div class="edit-btn-box" @click="onShowModal">
            <img class="edit-icon ml8" src="@/assets/img/tool/edit.svg" />
          </div>
        </div>
        <div v-if="customerInfo.other_info && customerInfo.other_info.length">
          <div
            v-for="(field, index) in customerInfo.other_info"
            :key="index"
            class="line-1 mt12"
          >
            <span class="cl69">{{ field.name || '--' }}：</span>
            <span class="cl29">{{ field.value || '--' }}</span>
          </div>
        </div>
        <div v-if="customerInfo.qw_unionid" class="mt24 flex flex-align-center">
          <div class="clone-class line-1">
            <span class="cl69">unionid：</span>
            <span class="cl29">{{ customerInfo.qw_unionid }}</span>
          </div>
          <div
            class="fs14 cl9c"
            @click.stop="cloneUnionid(customerInfo.qw_unionid)"
          >
            复制
          </div>
        </div>
        <!-- <div class="mt12 flex flex-align-center">
          <div class="flex-1 line-1">
            <span class="cl69">手机号：</span>
            <span class="cl29">
              {{
                customerInfo.phone
                  ? mobileSensitivity(customerInfo.phone)
                  : '--'
              }}
            </span>
          </div>
          <div class="flex-1 line-1">
            <span class="cl69">生日：</span>
            <span class="cl29">
              {{ customerInfo.birthday || '--' }}
            </span>
          </div>
        </div>
        <div class="mt12 flex flex-align-center">
          <div class="flex-1 line-1">
            <span class="cl69">注册日期：</span>
            <span class="cl29">{{ customerInfo.register_date || '--' }}</span>
          </div>
          <div class="flex-1 line-1">
            <span class="cl69">备注：</span>
            <span class="cl29">{{ customerInfo.remark || '--' }}</span>
          </div>
        </div>
        <div class="line-1 mt12">
          <span class="cl69">归属门店：</span>
          <span class="cl29">{{ customerInfo.store_name || '--' }}</span>
        </div> -->
      </div>
    </div>
    <div class="module-container mb8 bgf plr15">
      <div
        class="module-title relative flex flex-align-center bdb fs16 fw500 mr8 pl12"
      >
        我标记的标签
      </div>
      <div
        v-if="myLabelList && myLabelList.length"
        class="module-content pt12 pb4 mb8"
      >
        <div
          v-for="group in myLabelList"
          :key="group.group_id"
          class="label-group flex"
          @click="openLabelPop(group)"
        >
          <div
            class="cl29 fw500 mr8 flex-s0 lh26"
            :style="{ 'min-width': groupNameMaxLength + 'em' }"
          >
            {{ ellipsisText(group.group_name || '--', 3) }}
          </div>
          <div class="flex-1 flex flex-wrap">
            <div
              v-for="label in group.label_list"
              :key="label.id"
              class="label-item bdr2 mr8 line-1 mb8 flex flex-align-center"
              :class="[
                label.tag_type == 'HQ' ? 'la-headquarters' : 'la-service'
              ]"
            >
              {{ label.name }}
              <div
                v-if="label.count"
                class="label-circle ml8 flex flex-justify-center flex-align-center bgf"
              >
                {{ label.count }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="ptb12 tc cl9c">暂无标记的标签</div>
    </div>
    <div class="module-container mb8 bgf plr15">
      <div
        class="module-title relative flex flex-align-center bdb fs16 fw500 mr8 pl12"
      >
        其他跟进成员
      </div>
      <div
        v-if="
          (otherLabelList && otherLabelList.length) ||
          (labelList.member_friends && labelList.member_friends.length)
        "
        class="module-content pt12 pb4 mb8"
      >
        <!-- <div
          v-if="labelList.member_allot && labelList.member_allot.length"
          class="mb8"
        >
          <div
            v-for="(item, index) in labelList.member_allot"
            :key="index"
            class="flex flex-align-center"
          >
            <span class="flex-s0 cl69 mr4">{{ item.role_name || '--' }}：</span>
            <div class="flex-1 flex flex-align-center">
              <img
                class="flex-s0 avatar-img bdr2 mr4"
                :src="item.qw_thumb_avatar"
              />
              <span class="txt-blue line-1">
                {{ item.qw_name }}/{{ item.qw_department_name }}
              </span>
            </div>
          </div>
        </div> -->
        <div
          v-if="labelList.member_friends && labelList.member_friends.length"
          class="flex"
        >
          <span class="flex-s0 cl69 mr4 lh32">互为好友：</span>
          <div class="flex-1">
            <img
              v-for="(item, index) in labelList.member_friends"
              :key="index"
              class="flex-s0 avatar-img bdr2 mr4 mb8"
              :src="item.qw_thumb_avatar"
            />
          </div>
        </div>
        <div
          v-for="group in otherLabelList"
          :key="group.group_id"
          class="label-group flex"
        >
          <div
            class="cl29 fw500 mr8 flex-s0 lh26"
            :style="{ 'min-width': groupNameMaxLength + 'em' }"
          >
            {{ ellipsisText(group.group_name || '--', 3) }}
          </div>
          <div class="flex-1 flex flex-wrap">
            <div
              v-for="label in group.label_list"
              :key="label.id"
              class="label-item bdr2 mr8 line-1 mb8 flex flex-align-center"
              :class="[
                label.tag_type == 'HQ' ? 'la-headquarters' : 'la-service'
              ]"
            >
              {{ label.name }}
              <div
                v-if="label.count"
                class="label-circle ml8 flex flex-justify-center flex-align-center bgf"
              >
                {{ label.count }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="ptb12 tc cl9c">暂无其他跟进成员</div>
    </div>
    <div class="module-container mb8 bgf plr15">
      <div
        class="module-title relative flex flex-align-center bdb fs16 fw500 mr8 pl12"
      >
        总部标签
      </div>
      <div
        v-if="hqLabelList && hqLabelList.length"
        class="module-content pt12 pb4 mb8"
      >
        <div
          v-for="group in hqLabelList"
          :key="group.group_id"
          class="label-group flex"
        >
          <div
            class="cl29 fw500 mr8 flex-s0 lh26"
            :style="{ 'min-width': groupNameMaxLength + 'em' }"
          >
            {{ ellipsisText(group.group_name || '--', 3) }}
          </div>
          <div class="flex-1 flex flex-wrap">
            <div
              v-for="label in group.label_list"
              :key="label.id"
              class="label-item bdr2 mr8 line-1 mb8 flex flex-align-center"
              :class="[
                label.tag_type == 'HQ' ? 'la-headquarters' : 'la-service'
              ]"
            >
              {{ label.name }}
              <div
                v-if="label.count"
                class="label-circle ml8 flex flex-justify-center flex-align-center bgf"
              >
                {{ label.count }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="ptb12 tc cl9c">暂无总部标签</div>
    </div>
    <!-- <img
      class="add-icon"
      src="@/assets/img/customer-portrait/addicon.svg"
      @click="onAddHandle"
    /> -->
    <PopupView
      ref="labelSelectCover"
      :is_close="false"
      position="bottom"
      direction="below"
    >
      <div class="label-pop pb14 pt" @click.stop="">
        <div class="label-pop-box">
          <LinkAgeScroll
            :view-list="reacData.serviceList"
            ref="allServiceLabel"
            type="SERVICE"
            :active-group="reacData.activeGroup"
            :labelShow="reacData.labelShow"
            :select-tags="reacData.selectedTags"
            :scroll-height="400"
            @clickAddTags="selctServiceTagId"
          />
        </div>
        <div class="btn-box flex pt12 pb12">
          <button
            class="cancel-btn mr8 flex-s0 flex-g0"
            @click="labelSelectCoverClose"
          >
            取消
          </button>
          <button class="confirm-btn flex-s1 flex-g1" @click="custmerAddTags">
            确认
          </button>
        </div>
      </div>
    </PopupView>
  </div>
</template>

<script>
import {
  getCustomerLabeledsV2,
  getGuideTagList,
  addTags,
  getCustomerLabel
} from '@/service/customer.service.js'
// import PopupView from '@/components/PopupView/index.vue'
import PopupView from '@/components/popupView/index'
import { Toast, Popup } from 'vant'
import { store } from '@/store'
import LinkAgeScroll from '@/components/label/llabelScroll/index.vue'
import { setClipboardData } from '@/utils/nwechat.js'

export default {
  components: {
    [Popup.name]: Popup,
    PopupView,
    LinkAgeScroll
  },
  props: {
    customerInfo: {
      // 会员详情
      type: Object,
      default: () => ({})
    },
    tagSubData: {
      // 会员主体id数组
      type: [Array, Object],
      default: () => []
    }
  },
  data() {
    return {
      labelList: {}, // 标签列表
      params: {}, // 路由参数
      groupNameMaxLength: 1, // 标签组名称最大长度
      hqLabelList: [], // 总部标签
      myLabelList: [], // 我的标签
      otherLabelList: [], // 其他成员的标签
      reacData: {
        serviceList: [],
        selectedTags: [],
        tagParams: {
          ids: []
        },
        labelShow: false,
        activeGroup: ''
        // labelSelectCoverShow: false
      }
    }
  },
  mounted() {
    // const query = this.customerInfo
    // if (query) {
    //   this.params = query
    //   this.getLabelData()
    // }
  },
  watch: {
    customerInfo(newval) {
      if (newval) {
        this.params = newval
        this.getLabelData()
        this.getAllServiceLabel()
      }
    }
  },
  methods: {
    // 组件激活
    // activated() {
    // 	this.getLabelData()
    // },
    // 获取标签数据
    async getLabelData() {
      const { qw_external_userid, crm_yw_id } = this.params
      const { code, data } = await getCustomerLabeledsV2(
        qw_external_userid || crm_yw_id
      )
      if (code == 200) {
        const { hq, my_tag, other_tag } = data
        this.labelList = data
        this.hqLabelList = this.formatLabelData(hq)
        this.myLabelList = this.formatLabelData(my_tag)
        this.otherLabelList = this.formatLabelData(other_tag)
        const calculateList = [
          ...this.hqLabelList,
          ...this.myLabelList,
          ...this.otherLabelList
        ]
        var groupNameMaxLength = 1
        for (let i = 0; i < calculateList.length; i++) {
          const name = calculateList[i]?.group_name || ''
          if (name.length > groupNameMaxLength) {
            groupNameMaxLength = name.length
          }
        }
        this.groupNameMaxLength =
          groupNameMaxLength > 7 ? 7 : groupNameMaxLength
      }
    },
    // 格式化标签分组
    formatLabelData(data) {
      try {
        if (!data || !Array.isArray(data)) return []
        const newData = []
        for (let i = 0; i < data.length; i++) {
          const findIndex = newData.findIndex(
            (item) => item.group_id === data[i]['group_id']
          )
          if (findIndex > -1) {
            newData[findIndex].label_list.push({ ...data[i] })
          } else {
            newData.push({
              group_id: data[i].group_id,
              group_name: data[i].group_name,
              label_list: [{ ...data[i] }]
            })
          }
        }
        return newData
      } catch (error) {
        console.log(error)
        return []
      }
    },
    // 手机号敏感处理
    mobileSensitivity(val) {
      if (!val) return ''
      var reg = /^(\d{3})\d{4}(\d{4})$/
      return val.replace(reg, '$1****$2')
    },
    onShowModal() {
      this.$emit('onShowModal')
    },
    // 打标签
    onAddHandle() {
      const { qw_external_userid } = this.params
      this.$router.push({
        name: 'customer-tag',
        params: { id: qw_external_userid }
      })
    },
    // 文字超出省略 num: 前后保留多少位
    ellipsisText(value, num = 4) {
      if (!value) return ''
      if (value.length > 2 * num + 1) {
        let arr = value.split('')
        const newArr = [
          ...arr.slice(0, num),
          '...',
          ...arr.slice(arr.length - num, arr.length)
        ]
        return newArr.join('')
      } else {
        return value
      }
    },
    async getAllServiceLabel(type) {
      // getGuideTagList(type).then(res => {
      //   let data = res.data
      //   if (type == 'HQ') {
      //     this.reacData.hqList = data
      //     // allServiceLabel.value.init()
      //     this.$refs['allServiceLabel'].init()
      //   } else if(type == 'SERVICE') {
      //     this.reacData.serviceList = data || []
      //   }
      //   return Promise.resolve()
      // }).catch(err => {
      //   return Promise.reject(err)
      // })
      try {
        const { data } = await getCustomerLabel(this.params.qw_external_userid)
        console.log('标签结构', data)
        this.reacData.serviceList = data
      } catch (error) {
        console.log('所有标签', error)
        this.reacData.serviceList = []
      }
    },
    openLabelPop(group) {
      console.log('选中分组', this.tagSubData?.qw_subject.yw_id)
      // 没有主体信息的不能打标签
      if (!this.tagSubData?.qw_subject.yw_id) return
      if (group.label_list[0].tag_type != 'SERVICE') return
      this.reacData.activeGroup = group.group_name
      console.log('获取组件中的函数', this.$refs['allServiceLabel'])
      // this.$refs['allServiceLabel'].clearAllTagIds()
      let arr = []
      this.myLabelList.forEach((item) => {
        item.label_list.forEach((sItem) => {
          // arr.push(sItem.id)
          arr.push({
            group_name: sItem.group_name,
            id: sItem.id,
            tag_name: sItem.name,
            tag_type: sItem.tag_type
          })
        })
      })
      console.log('过滤出来的标签--------------', arr)
      this.reacData.selectedTags = arr
      // labelSelectCover.value.show()
      this.$refs['labelSelectCover'].show()
      this.$nextTick(() => {
        // allServiceLabel.value.init()

        this.reacData.labelShow = !this.reacData.labelShow
        this.$refs['allServiceLabel'].init()
      })
    },
    closeLabelPop() {
      // labelSelectCover.value.close()
      this.$refs['labelSelectCover'].close()
    },
    selctServiceTagId(arr) {
      console.log('选中标签', arr)
      this.reacData.tagParams.ids = arr
    },
    // 为客户打标签
    async custmerAddTags() {
      const { qw_external_userid } = this.params
      let params = {
        token: store.getters.token,
        act: 'add',
        tag: this.reacData.tagParams.ids,
        sub_id: this.tagSubData?.qw_subject.yw_id || '',
        customer: [
          {
            id: '',
            yw_id: qw_external_userid
          }
        ]
      }
      const { code } = await addTags(params)
      if (code == 200) {
        this.getLabelData()
        this.getLabelData()
        this.getAllServiceLabel()
        // uni.showToast({
        //   title: '标签标记成功',
        //   icon: 'none'
        // })
        Toast('标签标记成功')
        // reacData.labelShow = false
        // labelSelectCover.value.close()
        this.$refs['labelSelectCover'].close()
      }
    },
    labelSelectCoverClose() {
      // reacData.labelShow = false
      // labelSelectCover.value.close()
      this.$refs['labelSelectCover'].close()
    },
    async cloneUnionid(val) {
      let result = await setClipboardData(val)
      if (result) {
        uni.showModal({
          title: '已成功复制客户unionid',
          showCancel: false
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.clone-class {
  max-width: 140px;
}
.cl9c {
  color: #86909c;
}
.cl69 {
  color: #4e5969;
}
.cl29 {
  color: #1d2129;
}
.bgf5 {
  background-color: #f5f5f5;
}
.plr15 {
  padding-left: 15px;
  padding-right: 15px;
}
.ptb12 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.pb3 {
  padding-bottom: 3px;
}
.plr6 {
  padding-left: 6px;
  padding-right: 6px;
}
.pl12 {
  padding-left: 12px;
}
.bdr2 {
  border-radius: 2px;
}
.mr8 {
  margin-right: 8px;
}
.mr4 {
  margin-right: 4px;
}
.mb8 {
  margin-bottom: 8px;
}
.overflow-h {
  overflow: hidden;
}
.icon {
  width: 16px;
  height: 16px;
}
.plr6 {
  padding-left: 6px;
  padding-right: 6px;
}
.lh20 {
  line-height: 20px;
}
.container {
  min-height: 100vh;
}
.pb4 {
  padding-bottom: 4px;
}
.lh26 {
  height: 26px;
  line-height: 26px;
}
.lh32 {
  height: 32px;
  line-height: 32px;
}
.add-icon {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: calc(70px + env(safe-area-inset-bottom) / 2);
  right: 15px;
}
.module-container {
  .module-title {
    height: 44px;

    &::after {
      width: 4px;
      height: 20px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      content: '';
      background: @theme-color;
    }
  }
  .module-content {
    .avatar-img {
      width: 32px;
      height: 32px;
    }
    .edit-btn-box {
      padding: 12px;
      // padding-bottom: env(safe-area-inset-bottom) !important;
      margin: -12px;
      font-size: 0;

      .edit-icon {
        width: 16px;
        height: 16px;
      }
    }
    .label-item {
      max-width: 100%;
      height: 26px;
      line-height: 26px;
      padding: 0 8px;
      color: #ffffff;
    }
    .label-circle {
      min-width: 16px;
      height: 16px;
      border-radius: 999px;
      padding: 0 3px;
    }
    // 总部标签
    .la-headquarters {
      background-color: @hq-label-color;

      .label-circle {
        color: @hq-label-color;
      }
    }
    //服务标签
    .la-service {
      background-color: @se-label-color;

      .label-circle {
        color: @se-label-color;
      }
    }
  }
}

.label-pop {
  // width: 100vw;
  padding-bottom: calc(env(safe-area-inset-bottom) / 2);
  .label-pop-box {
    height: 400px;
    overflow: hidden;
  }

  .btn-box {
    padding: 0 15px;
    .cancel-btn,
    .confirm-btn {
      line-height: 44px;
      height: 44px;
      border-radius: 5px;
      text-align: center;
      font-size: 14px;
      outline: none;
      border: 0;
    }
    .cancel-btn {
      width: 61px;
      color: #171717;
      background-color: #f5f7fa;
    }
    .confirm-btn {
      color: #fff;
      background-color: @theme-color;
    }
  }
}
</style>
