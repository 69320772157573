<template>
  <div class="container cl17 bgf fs14 flex flex-direction-column">
    <!-- <div class="container-header flex-s0">
      <div class="fs16 fw500 mb5">选择你客户的个性标签</div>
      <div class="text-color fs12">让你更方便了解客户，为你节省大量时间</div>
    </div> -->
    <div class="container-wrapper flex-1 flex">
      <div
        class="menu-wrapper flex-s0"
        ref="menuScroll"
        :style="{ height: props.scrollHeight + 'px' }"
        @touchmove.stop=""
      >
        <div class="bgf">
          <van-sidebar v-model="activeMenu">
            <van-sidebar-item
              v-for="(item, index) in customerLabel"
              :key="index"
              :title="item.group_name"
              :class="{
                'transform-scroll': true,
                'menu-item-last': activeMenu - 1 === index,
                'menu-item-next': activeMenu + 1 === index
              }"
              :id="'menu' + index"
              @click="selectMenu(index)"
            />
          </van-sidebar>
        </div>
      </div>
      <div
        class="label-wrapper flex-1"
        ref="labelScroll"
        :style="{ height: props.scrollHeight + 'px' }"
        @touchmove.stop=""
      >
        <div
          v-for="(item, index) in customerLabel"
          :key="index"
          :id="'label-cell' + index"
          class="label-cell transform-scroll"
        >
          <p class="fw500 mb8 pt12">{{ item.group_name }}</p>
          <div class="flex flex-wrap">
            <div
              v-for="label in item.group_list"
              :key="label.id"
              class="label-item mr8"
              @click="onHandle(label, index)"
            >
              <div
                class="label-item-title border-box fs12 flex flex-align-center wb"
                :class="{ 'label-item-active': label.is_labeled != 2 }"
              >
                {{ label.label_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Toast } from 'vant'
import { nextTick, onMounted, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { getCurExternalContact } from '@/utils/nwechat.js'
import { getCustomerLabel, labelCustomers } from '@/service/customer.service.js'

const route = useRoute()
const userId = ref('') // 用户id
const activeMenu = ref(0) // 选中的标签类索引
const customerLabel = ref([]) // 客户标签
const menuScroll = ref(null) // 菜单栏ref
const labelScroll = ref(null) // 内容区域ref
const labelCellHeights = ref([]) // 标签Html高度
const loading = ref(false) // 事件loading

// 当前选中的标签id 数组
const selectTagIds = ref([])
const selectTags = ref([])

const props = defineProps({
  //左右列表
  viewList: {
    type: Array,
    default: () => []
  },
  //左边区域的背景色
  leftBgColor: {
    type: String,
    default: '#F7F7F7'
  },
  // 标签类型
  type: {
    type: String,
    default: 'SERVICE'
  },
  // 左侧导航的key
  leftTitleKey: {
    type: String,
    default: 'name'
  },
  // 右侧遍历的key
  rightListKey: {
    type: String,
    default: 'tag'
  },
  // 右侧标签的key
  rightTitleKey: {
    type: String,
    default: 'name'
  },
  // 回显的标签集合
  selectTags: {
    type: Array,
    default: () => []
  },
  labelShow: {
    // 用于数据更新时回显bug修复
    type: Boolean,
    default: () => false
  },
  activeGroup: {
    // 默认选中标签组
    type: String,
    default: ''
  },
  scrollHeight: {
    type: Number,
    default: 329
  }
})

const emit = defineEmits(['clickAddTags'])

// onMounted(() => {
//   initData()
//   initScroll()
// })

watch(
  () => props.labelShow,
  (val) => {
    console.log('激活分组', props.labelShow, props.activeGroup)
    let index = customerLabel.value.findIndex((item) => {
      return item.group_name === props.activeGroup
    })
    // setTimeout(() => {
    // document.getElementById('menu' + index).scrollIntoView(true)
    selectMenu(index)
    // }, 800)
  }
)

// 组件初始化
function init() {
  initData()
  initScroll()
}

// 初始化数据
const initData = async () => {
  try {
    // 从父组件中获取数据
    customerLabel.value = props.viewList || []
    selectTagIds.value = props.selectTags || []

    nextTick(() => {
      calulateHeight()
    })
  } catch (error) {
    console.log(error)
  }
}

// 添加labelScroll的scroll事件
const initScroll = () => {
  labelScroll.value.addEventListener('scroll', (e) => {
    const scrollY = labelScroll.value.scrollTop

    labelCellHeights.value.forEach((item, i) => {
      let height1 = labelCellHeights.value[i]
      let height2 = labelCellHeights.value[i + 1]
      let height3 =
        labelCellHeights.value[labelCellHeights.value.length - 1] -
        labelScroll.value.clientHeight
      if (scrollY >= height1 && scrollY < height2) {
        if ((activeMenu.value < i || scrollY < height3, height3)) {
          // console.log('菜单滚动区域--', labelScroll.value.scrollHeight, labelScroll.value.scrollTop)
          // 当前
          if (
            activeMenu.value !== i &&
            labelScroll.value.scrollTop + props.scrollHeight <=
              labelScroll.value.scrollHeight
          ) {
            // console.log('菜单滚动区域', height1, height2, height3)
            document.getElementById('menu' + i).scrollIntoView(true)
          }
          activeMenu.value = i
        }
      }
    })
  })
}
// 获取label-cell标签的可视高度
const calulateHeight = () => {
  let foodlist = labelScroll.value.getElementsByClassName('label-cell')
  let height = 0
  const data = []
  data.push(height)
  for (let i = 0; i < foodlist.length; i++) {
    height += foodlist[i].clientHeight
    data.push(height)
  }
  //   foodlist.forEach((item) => {
  //     height += item.clientHeight
  //     data.push(height)
  //   })
  labelCellHeights.value = data
}
// 选择标签类, 滑动到该类的标签
const selectMenu = (i) => {
  activeMenu.value = i
  // document.getElementById('label-cell' + i).scrollIntoView(true)
  labelScroll.value.scroll({
    top: labelCellHeights.value[i], //向上移动的距离，如果有fixede布局， 直接减去对应距离即可
    behavior: 'smooth' // 平滑移动
  })

  setTimeout(() => {
    activeMenu.value = i
  }, 800)
}

// 根据id删除对象数组中的对应元素
function ArrDelete(arr, id) {
  let index = arr.findIndex((item) => {
    return item.id === id
  })
  if (index == -1) return
  arr.splice(index, 1)
}

// 打标签
const onHandle = async (data = {}, groupIndex) => {
  // const { id, marketing_suggestions, is_labeled } = data
  // if (loading.value) return false

  // loading.value = true
  // Toast.loading({
  //   message: '加载中',
  //   forbidClick: true,
  //   duration: 0
  // })
  // const res = await labelCustomers({
  //   external_userid: userId.value,
  //   label_id: id,
  //   marketing_suggestions,
  //   status: is_labeled == 1 ? 2 : 1
  // })
  // loading.value = false
  // Toast.clear()
  console.log('当前标签', data)
  // let arr = new Set(selectTagIds.value)
  let arr = JSON.parse(JSON.stringify(selectTagIds.value))

  if (data.is_labeled == 1) {
    // 取消选中
    data.is_labeled = 2
    // arr.delete(data.id)
    ArrDelete(arr, data.id)
  } else {
    data.is_labeled = 1
    // arr.add(data.id)
    arr.push({
      group_name: customerLabel.value[groupIndex].group_name,
      id: data.id,
      tag_name: data.label_name,
      tag_type: customerLabel.value[groupIndex].group_tag_type
    })
  }

  // 判断标签组是否是互斥
  const type = customerLabel.value[groupIndex]?.type
  if (type === 'MUTEX' && data.is_labeled == 1) {
    const length = customerLabel.value[groupIndex].group_list.length
    for (let i = 0; i < length; i++) {
      const item = customerLabel.value[groupIndex].group_list[i]
      if (item.id !== data.id) {
        customerLabel.value[groupIndex].group_list[i].is_labeled = 2
        // arr.delete(item.id)
        ArrDelete(arr, item.id)
      }
    }
  }
  selectTagIds.value = [...arr]

  emit('clickAddTags', selectTagIds.value)
  // console.log('当前选中标签', selectTagIds.value)
}

defineExpose({ init, selectMenu })

// 获取标签组列表
// const getLabelList = async () => {
//   try {
//     Toast.loading({
//       message: '加载中',
//       forbidClick: true,
//       duration: 0
//     })
//     const { data } = await getCustomerLabel(userId.value)
//     Toast.clear()
//     console.log('标签结构', data)
//     customerLabel.value = data
//   } catch (error) {
//     Toast.clear()
//   }
// }
</script>

<style lang="less" scoped>
@import './tag.less';
</style>
